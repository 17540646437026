import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Sidebar.css';

function Sidebar() {
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    // Função para buscar categorias e remover duplicatas
    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://suplementos.horizontecnologia.com.br/api/categorias');
        const categoriasUnicas = response.data.reduce((acc, categoria) => {
          // Verifica se a categoria já existe no acumulador pelo nome
          if (!acc.some((cat) => cat.nome === categoria.nome)) {
            acc.push(categoria);
          }
          return acc;
        }, []);
        setCategorias(categoriasUnicas);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    fetchCategorias();
  }, []);

  return (
    <div className="sidebar">
      <h2>Categorias</h2>
      {categorias.length > 0 ? (
        <ul>
          {categorias.map((categoria) => (
            <li key={categoria.id}>{categoria.nome}</li>
          ))}
        </ul>
      ) : (
        <p>Nenhuma categoria disponível.</p>
      )}
    </div>
  );
}

export default Sidebar;
