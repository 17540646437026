// ~/loja-suplementos/frontend/src/components/Home.js
import React, { useEffect, useState } from 'react';
import BannerCarousel from './Carousel';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import './Home.css';

function Home() {
  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    // Use HTTPS para a requisição da API
    axios
      .get('https://suplementos.horizontecnologia.com.br/api/produtos')
      .then((response) => {
        setProdutos(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar produtos:', error);
      });
  }, []);

  return (
    <Container fluid className="home-container">
      {/* Carrossel de Banner */}
      <BannerCarousel />

      {/* Seção de Produtos em Destaque */}
      <div className="featured-products mt-5">
        <h2>Produtos em Destaque</h2>
        <div className="product-list d-flex justify-content-between flex-wrap">
          {produtos.length > 0 ? (
            produtos.map((produto) => (
              <div className="product-card" key={produto.id}>
                <img
                  src={produto.imagem || '/images/default.png'}
                  alt={produto.nome}
                  className="product-image"
                />
                <div className="product-info">
                  <h3>{produto.nome}</h3>
                  <p>{produto.descricao}</p>
                  <span className="product-price">R$ {produto.preco.toFixed(2)}</span>
                  <button className="product-button">Ver Detalhes</button>
                </div>
              </div>
            ))
          ) : (
            <p>Nenhum produto disponível no momento.</p>
          )}
        </div>
      </div>
    </Container>
  );
}

export default Home;
