// ~/loja-suplementos/frontend/src/index.js
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa Bootstrap
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
