// ~/loja-suplementos/frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './components/Home';
import Produtos from './pages/Produtos';
import Carrinho from './pages/Carrinho';
import './App.css'; // Importar CSS global para o layout

function App() {
  return (
    <Router>
      <div className="app-layout">
        {/* Sidebar fixo */}
        <Sidebar />

        {/* Conteúdo Principal */}
        <div className="main-content">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/produtos" element={<Produtos />} />
            <Route path="/carrinho" element={<Carrinho />} />
            <Route path="*" element={<div>Erro 404: Página não encontrada</div>} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
