// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `// ~/loja-suplementos/frontend/src/components/Carousel.css
.carousel-item img {
  height: 500px;
  object-fit: cover;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Carousel.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["// ~/loja-suplementos/frontend/src/components/Carousel.css\n.carousel-item img {\n  height: 500px;\n  object-fit: cover;\n}\n\n.carousel-caption {\n  background: rgba(0, 0, 0, 0.5);\n  padding: 15px;\n  border-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
