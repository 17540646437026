// ~/loja-suplementos/frontend/src/components/Carousel.js
import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Carousel.css'; // Estilos personalizados para o carrossel (opcional)

function BannerCarousel() {
  return (
    <Carousel>
      {/* Slide 1 */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/banner1.png"
          alt="Primeiro Slide"
        />
        <Carousel.Caption>
          <h3>Bem-vindo à Loja de Suplementos</h3>
          <p>Encontre os melhores produtos para sua saúde e bem-estar.</p>
        </Carousel.Caption>
      </Carousel.Item>

      {/* Slide 2 */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/banner2.png"
          alt="Segundo Slide"
        />
        <Carousel.Caption>
          <h3>Ofertas Especiais</h3>
          <p>Suplementos com descontos incríveis para você.</p>
        </Carousel.Caption>
      </Carousel.Item>

      {/* Slide 3 */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/banner3.png"
          alt="Terceiro Slide"
        />
        <Carousel.Caption>
          <h3>Qualidade e Confiança</h3>
          <p>Produtos selecionados de alta qualidade e eficácia comprovada.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default BannerCarousel;

