// ~/loja-suplementos/frontend/src/api.js
import axios from 'axios';

// Defina a baseURL como a URL do seu backend, por exemplo:
const api = axios.create({
  baseURL: 'http://127.0.0.1:3000/api' // URL do backend local
});

export default api;

