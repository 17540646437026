// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ~/frontend/src/styles/Sidebar.css */

.sidebar {
  background-color: #343a40;
  color: #ffffff;
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.main-content {
  margin-left: 250px; /* Largura do sidebar */
  padding: 20px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .main-content {
    margin-left: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,gBAAgB;EAChB,aAAa;EACb,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,kBAAkB,EAAE,uBAAuB;EAC3C,aAAa;AACf;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":["/* ~/frontend/src/styles/Sidebar.css */\n\n.sidebar {\n  background-color: #343a40;\n  color: #ffffff;\n  width: 250px;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  left: 0;\n  overflow-y: auto;\n  padding: 20px;\n  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);\n  z-index: 1000;\n}\n\n.main-content {\n  margin-left: 250px; /* Largura do sidebar */\n  padding: 20px;\n}\n\n@media (max-width: 768px) {\n  .sidebar {\n    width: 100%;\n    height: auto;\n    position: relative;\n  }\n\n  .main-content {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
