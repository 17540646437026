// ~/loja-suplementos/frontend/src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingCart, FaUserCircle, FaHeart } from 'react-icons/fa';
import '../styles/Header.css';

function Header() {
  return (
    <header className="header">
      {/* Barra Superior */}
      <div className="top-bar">
        <p>Pix e Boleto 5% de desconto • Frete grátis* • Parcelamento em 6x sem juros • 1x no cartão</p>
      </div>

      {/* Menu Principal */}
      <div className="main-header">
        <div className="container">
          {/* Logo */}
          <div className="logo">
            <Link to="/">
              <img src="/images/logo.png" alt="Logo da Loja" className="logo-image" />
            </Link>
          </div>

          {/* Barra de Busca */}
          <div className="search-bar">
            <input type="text" placeholder="O que você está procurando?" className="search-input" />
            <button className="search-button">Buscar</button>
          </div>

          {/* Ícones de Navegação */}
          <div className="nav-icons">
            <Link to="/login" className="nav-icon-link">
              <FaUserCircle /> Entrar
            </Link>
            <Link to="/favoritos" className="nav-icon-link">
              <FaHeart /> Favoritos
            </Link>
            <Link to="/carrinho" className="nav-icon-link">
              <FaShoppingCart /> Carrinho
            </Link>
          </div>
        </div>
      </div>

      {/* Menu de Navegação */}
      <nav className="nav">
        <Link to="/lancamentos" className="nav-link">Lançamentos</Link>
        <Link to="/proteinas" className="nav-link">Proteínas</Link>
        <Link to="/barras-de-proteinas" className="nav-link">Barras de Proteínas</Link>
        <Link to="/pre-treinos" className="nav-link">Pré-treinos</Link>
        <Link to="/creatinas" className="nav-link">Creatinas</Link>
        <Link to="/kits" className="nav-link">Kits Promocionais</Link>
      </nav>
    </header>
  );
}

export default Header;
