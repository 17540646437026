// ~/loja-suplementos/frontend/src/pages/Produtos.js
import React, { useEffect, useState } from 'react';
import api from '../api'; // Importe o `api.js` para usar o axios

function Produtos() {
  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    // Requisição para buscar produtos do backend
    api.get('/produtos')
      .then(response => {
        setProdutos(response.data); // Definir o estado com os produtos retornados
      })
      .catch(error => {
        console.error('Erro ao buscar produtos:', error);
      });
  }, []);

  return (
    <div>
      <h1>Lista de Produtos</h1>
      <ul>
        {produtos.map(produto => (
          <li key={produto.id}>{produto.nome} - R${produto.preco}</li>
        ))}
      </ul>
    </div>
  );
}

export default Produtos;

